@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif; */
}

.main-container {
  overflow: hidden;
}

.main-container,
.main-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.dark .bg-light {
  background-color: #1a202c; /* Couleur de fond pour le mode sombre */
}

.dark .text-dark {
  color: #f7fafc; /* Couleur du texte pour le mode sombre */
}
