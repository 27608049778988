input[type="checkbox"]:checked + label span:first-of-type {
  background-color: #0067b2;
  border-color: #0067b2;
  color: #fff;
}

input[type="checkbox"]:checked + label span:nth-of-type(2) {
  text-decoration: line-through;
  color: #9ca3af;
}
